import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Max2xl, PX3, H1, Max5xl } from "../components/blocks"
import { PostIndex } from "../components/post"
import moment from "moment"

const getDate = post =>
  post.kind === "aktuelles"
    ? post.node.frontmatter.date
    : post.node.frontmatter.start

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const aktuelles = data.aktuelles.edges
    .map(x => {
      x.kind = "aktuelles"
      return x
    })
    .filter(
      post =>
        post.node.frontmatter.state === "archived" ||
        (post.node.frontmatter.archiveAt &&
          moment().isSameOrAfter(post.node.frontmatter.archiveAt, "day"))
    )

  const appointments = data.appointments.edges
    .map(x => {
      x.kind = "appointment"
      return x
    })
    .filter(post =>
      post.node.frontmatter.state === "archived" ||
      (post.node.frontmatter.archiveAt &&
        moment().isSameOrAfter(post.node.frontmatter.archiveAt, "day")) ||
      post.node.frontmatter.end
        ? moment().isAfter(post.node.frontmatter.end, "day")
        : moment().isAfter(post.node.frontmatter.start, "day")
    )

  const posts = [...appointments, ...aktuelles].sort((a, b) =>
    moment(getDate(b)).diff(getDate(a))
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Archivierte Beiträge" />
      <Max5xl className="mb-3 pb-2">
        <Max2xl className="">
          <PX3>
            <H1>Archiv</H1>
            {posts.length > 0 ? (
              <PostIndex posts={posts} />
            ) : (
              <p>Es befinden sich keine Einträge im Archiv</p>
            )}
          </PX3>
        </Max2xl>
      </Max5xl>
    </Layout>
  )
}

export const query = graphql`
  query Archive {
    site {
      siteMetadata {
        title
      }
    }

    ...AllAppointmentsDesc

    aktuelles: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/aktuelles/**/*.md" } }

      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...PostIndexInformation
    }
  }
`
